import React, { useEffect, useState } from 'react';
import { FETCH_DATA } from './config';
import { getCookie } from './helper';
import './App.css';
import ErrorBoundary from './ErrorBoundary';
import loader from './assets/loader.gif'

export const xf_session = getCookie('xf_user');
const showEmpty = document.getElementById('report-info-plugin')?.dataset?.showempty;

function App () {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = (url, params = {}) => {
        setLoading(true);

        const formattedParams = {};
        for (let key in params) {
            if (!params.hasOwnProperty(key) || !params[key]) {
                continue;
            }
            formattedParams[key] = params[key];
        }

        let fetch_url = new URL(url);
        fetch_url.search = new URLSearchParams(formattedParams).toString();
        fetch(fetch_url)
            .then((response) => {
                if (response.status !== 200) {
                    setLoading(false)
                    return;
                }
                return response.json();
            })
            .then((data) => {
                setData(data?.data);
                setLoading(false)
            });
    };


    useEffect(() => {
        const params = {
            xf_session
        }

        fetchData(FETCH_DATA, params);

    }, []);

    return (
        <ErrorBoundary>
            { (data.needReport || data.overdueReports) ?
            <div className="report-info-block App">
                <div className="title">Информация по отчетам</div>
                {
                    loading ? <img src={loader} alt='Loading' /> : null
                }
                <ul>
                    {(data.needReport > 0 || showEmpty) &&
                    <li className="gb_li"><a href="/shopping/UserEstimeteIsNotBuild" target="_blank">Нужно сдать отчет <strong className="itemCount_ri"><span>{data.needReport}</span><span
                        className="arrow"></span></strong></a></li>
                    }
                    {(data.overdueReports > 0 || showEmpty) &&
                    <li className="gb_li"><a href="/shopping/UserShowEstimateOverdue" target="_blank">Отчёт просрочен <strong className="itemCount_ri"><span>{data.overdueReports}</span><span
                        className="arrow"></span></strong></a></li>
                    }
                </ul>
            </div> : null
            }
        </ErrorBoundary>
    );
}

export default App;
