const ORIGIN = document.location.protocol + '//' + document.location.host;

// production api
const FETCH_DATA_PROD = ORIGIN + '/conversationApi2/accounting/getReportQuantityForOrganizer';

// dev api
const FETCH_DATA_DEV = 'http://phalcon4.batikmsk.ru/api/accounting/getReportQuantityForOrganizer';

const isDev = document.location.host.includes('phalcon') || document.location.host.includes('localhost');

export const FETCH_DATA = isDev ? FETCH_DATA_DEV : FETCH_DATA_PROD;
