import moment from 'moment';

const HOUR = 60 * 60 * 1000;


export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function formatDate (timestamp) {
    if(!timestamp || (typeof timestamp !== 'number')){
        return
    }
    const now = moment();
    let result;
    if (now.diff(timestamp) < HOUR) {
        result = moment(timestamp).fromNow();
    } else {
        result = moment(timestamp).calendar();
    }
    return result;
}
