import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


const rootEl = document.getElementById('report-info-plugin')
if(rootEl) {
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('report-info-plugin')
    );
}
